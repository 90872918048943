<template>
  <app-list-view
    server-side
    app="questionnaire"
    model="masterquestionnaireheader"
    api-url="questionnaire/master-questionnaire-header/"
    :title="$t('menu.masterQuestionnaire')"
    :createTo="{ name: 'masterQuestionnaireCreate' }"
    :editTo="{ name: 'masterQuestionnaireEdit' }"
    :headers="headers"
  >
  </app-list-view>
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'masterQuestionnaireList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('fields.name'), value: 'name' },
        {
          text: this.$t('fields.questionType'),
          value: 'questionnaire_type_id.name'
        },
        { text: this.$t('fields.state'), value: 'state' },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  }
}
</script>
